import React, { Fragment, useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import Loading from "../components/Loading";
import LanguageIcon, { languages } from "../components/LanguageIcon";
import { VscGithub } from "react-icons/vsc";
import { RiExternalLinkLine } from "react-icons/ri";
import Particles from "../components/Particles";
import AdderuLLC from "../images/adderu-llc.png";
import WildIrisSalon from "../images/wild-iris-salon.png";
import BlueMoonApothecary from "../images/blue-moon-apothecary.png";
import Club4321 from "../images/club-4321.png";
import "../styles/home.css";

const Home = () => {
  const [repos, setRepos] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactsRef = useRef(null);

  useEffect(() => {
    axios
      .get("https://api.github.com/users/CarterCobb/repos")
      .then((res) => res.data)
      .then(setRepos);
    window.onresize = () => setWidth(window.innerWidth);
  }, []);

  if (repos === null) return <Loading />;

  return (
    <Fragment>
      <Header
        aboutRef={aboutRef}
        contactsRef={contactsRef}
        portfolioRef={portfolioRef}
        projectsRef={projectsRef}
      />
      <div id="cc-home-container">
        <section className="cc-sec1" ref={aboutRef}>
          <div className="cc-skewed1" />
          <Particles
            key={JSON.stringify({ w: width })}
            particleAmount={width / 25}
            w={width}
            h={1020}
            defaultSpeed={0.1}
            particleColor="rgb(0, 128, 0)"
            lineColor="rgb(106, 13, 173)"
          />
          <div id="cc-about-container">
            <div className="cc-about-section cc-about-skew">
              <div id="cc-carter-headshot" />
              <style>
                {`
                  /* React inline pseudo class injection*/
                  #cc-carter-headshot::before {
                    background-image: url("${repos[0].owner.avatar_url}");
                  }
                `}
              </style>
              <div id="cc-about-details">
                <h1>About</h1>
                <p>
                  I'm a software engineer. I have high proficiency in modern
                  technologies including but not limited to Node.js, React.js,
                  Docker, Terraform, AWS, and Solidity.
                  <br />
                  <br />
                  I started my programming journey with iOS apps for the App
                  Store. From there I began learning React.js and its related
                  technologies. Since then, I've explored and created projects
                  for many types of applications and servers.
                  <br />
                  <br />
                  Here are some programming languages and technologies I've
                  developed with:
                </p>
                <div id="cc-language-about-bar">
                  {languages.map((language, i) => (
                    <div
                      className="cc-language-about-icon"
                      key={i}
                      title={language}
                    >
                      <LanguageIcon
                        language={language}
                        size={30}
                        title={language}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cc-sec1" ref={projectsRef}>
          <div className="cc-skewed2" />
          <div id="cc-works-container">
            <h2 className="cc-header" style={{ color: "white" }}>
              Explore My Public Projects:
            </h2>
            <div id="cc-repo-card-container">
              {repos
                .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
                .map((repo, i) => (
                  <div key={i} className="cc-github-card">
                    <a
                      href={repo.html_url}
                      target="_blank"
                      rel="noreferrer"
                      className="cc-repo-link"
                    >
                      <h4 className="cc-repo-title">
                        <VscGithub size={30} style={{ marginRight: 5 }} />
                        {repo.name}
                        &nbsp;
                        <RiExternalLinkLine />
                      </h4>
                    </a>
                    <p>{repo.description}</p>
                    <p className="cc-language">
                      <LanguageIcon language={repo.language} />
                      &nbsp;
                      {repo.language}
                    </p>
                    <div className="cc-repo-topics">
                      {repo.topics.map((topic, j) => (
                        <div key={j} className="cc-repo-topic">
                          {topic}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
        <section className="cc-sec1" ref={portfolioRef}>
          <div className="cc-skewed2" />
          <div id="cc-home-contact-container">
            <br />
            <h1 id="portfolio">Portfolio</h1>
            <p>Explore work I've completed for clients and companies.</p>
            <br />
            <br />
            <br />
            <h2 className="cc-header" style={{ textAlign: "center" }}>
              Websites I've Built:
            </h2>
            <div id="cc-website-bar">
              <a
                href="https://mototr.ee"
                target="_blank"
                rel="noreferrer"
                className="cc-website-item-link"
              >
                <div className="cc-website-item">
                  <img src="https://cdn.mototr.ee/logo" />
                </div>
                <h3>MotoTree</h3>
              </a>
              <a
                href="https://www.cvlshop.com"
                target="_blank"
                rel="noreferrer"
                className="cc-website-item-link"
              >
                <div className="cc-website-item">
                  <img
                    src="https://www.cvlshop.com/assets/logo_black-BXn9I8HQ.png"
                    style={{
                      background: "white",
                      height: 150,
                      width: 150,
                    }}
                  />
                </div>
                <h3>CVL Shop</h3>
              </a>
              <a
                href="https://www.phantom-alliance.com"
                target="_blank"
                rel="noreferrer"
                className="cc-website-item-link"
              >
                <div className="cc-website-item">
                  <img src="https://www.phantom-alliance.com/favicon-32x32.png" />
                </div>
                <h3>Phantom Alliance</h3>
              </a>
              <a
                href="https://www.wildirissalon.com"
                target="_blank"
                rel="noreferrer"
                className="cc-website-item-link"
              >
                <div className="cc-website-item">
                  <img src={WildIrisSalon} alt="wild iris salon" />
                </div>
                <h3>Wild Iris Salon</h3>
              </a>
              {/* <a
                href="https://www.club4321.com"
                target="_blank"
                rel="noreferrer"
                className="cc-website-item-link"
              >
                <div className="cc-website-item">
                  <img src={Club4321} alt="club 4321" />
                </div>
                <h3>Club 4321</h3>
              </a> */}
            </div>
            <h2 className="cc-header" style={{ textAlign: "center" }}>
              Companies I've Worked With:
            </h2>
            <div id="cc-company-bar">
              <a
                href="https://www.harrislocalgov.com"
                target="_blank"
                rel="noreferrer"
                className="cc-company-item-link"
              >
                <div className="cc-company-item">
                  <img
                    src="https://www.harrislocalgov.com/wp-content/uploads/2017/11/Harris_Local_Gov_Logo_FullColor_FINAL_11.2.17-350x116.png"
                    alt="harris local government"
                  />
                </div>
                <h3>
                  Harris Local
                  <br />
                  Government
                </h3>
              </a>
              <a
                href="https://www.wtwco.com/en-US"
                target="_blank"
                rel="noreferrer"
                className="cc-company-item-link"
              >
                <div className="cc-company-item">
                  <img
                    src="https://media.wtwco.com/-/media/WTW/Logo/Logo_Desktop.svg?iar=0&modified=20220630202346&imgeng=meta_true&hash=56F49BEC51919C8E565A890FBF0C1B85"
                    alt="willis towers watson"
                  />
                </div>
                <h3>
                  Willis Towers
                  <br />
                  Watson
                </h3>
              </a>
              <a
                href="https://www.bechtel.com/"
                target="_blank"
                rel="noreferrer"
                className="cc-company-item-link"
              >
                <div className="cc-company-item">
                  <img
                    src="https://www.bechtel.com/App_Themes/BechtelCorporate/Images/c-logo.svg?iar=0&modified=20220630202346&imgeng=meta_true&hash=56F49BEC51919C8E565A890FBF0C1B85"
                    alt="bechtel"
                  />
                </div>
                <h3>Bechtel</h3>
              </a>
            </div>
          </div>
        </section>
      </div>
      <div ref={contactsRef}>
        <Footer />
      </div>
    </Fragment>
  );
};

export default Home;
